// import Vue from 'vue'
import Vue from 'vue'
// Menu
import Navigation from '@/components/shared/Navigation.vue'

// Icons
import Logo from '@/components/shared/Logo.vue'
import CompanyLogo from '@/components/shared/CompanyLogo.vue'

// Loader
import Loader from '@/components/shared/Loader.vue'


// import Login from '@/components/shared/Login.vue'

Vue.component('navigation', Navigation);
Vue.component('app-logo', Logo);
Vue.component('company-logo', CompanyLogo);
// Vue.component('login', Login);
Vue.component('loader', Loader);