<template>
  <article class="video_intercom">
    <section class="video_intercom__main">
      <v-container fluid>
        <v-layout row>
          <v-flex xs6 >
            <div class="video_intercom__info_section">
              <div class="video_intercom__info_section--header">
                <span v-if="!calling && !show_video_call">{{location_name}}</span>
                <span v-if="calling || show_video_call" class="calling_time">{{time}}</span>
                <span v-if="calling || show_video_call" class="calling_name">{{location_name}}</span>
                <span v-if="calling || show_video_call" class="calling_date">{{date}}</span>
              </div>
              <div class="video_intercom__info_section--callings_section" :class="calling || show_video_call ? 'on_call' : ''">
                <div v-if="!calling && !show_video_call">
                  <div class="img_section" :style="{'background': 'url(' + require('@/assets/img/imagen_principal.jpeg') + ') center no-repeat'}"></div>
                  <div class="video_intercom__info_section--date_time">
                    <span>{{ date }}</span><br>
                    <span>{{ time }}</span>
                  </div>
                </div>
                <div v-if="calling && !show_video_call" class="video_intercom__info_section--loader_block">
                  <div class="spinner">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="8" cy="8" r="7" stroke-width="1"/>
                    </svg>
                  </div>
                  <div class="video_intercom__info_section--calling_text">Llamando...</div>
                </div>
                <div v-if="show_video_call" class="video_intercom__info_section--call_container">
                  <v-card class="video_intercom__info_section--video_call">
                    <div id="meet"></div>
                  </v-card>
                </div>
              </div>
              <div class="video_intercom__info_section--logo" :class="!calling && !show_video_call ? 'wide' : 'narrow'">
                <img src="@/assets/img/logo_fuseaccess_blanco.png" >
              </div>              
            </div>            
          </v-flex>
          <v-flex xs6>
            <div class="video_intercom__buttons_section">
              <div v-for="sublocation in sublocations" :key="sublocation.id" 
              class="video_intercom__buttons_section--button" 
              :class="sublocation_on_call.id === sublocation.id ? 'selected' : ''"
              @click="callSublocation(sublocation)">
                <!-- <span class="name">{{sublocation.name.toUpperCase().split(" ").slice(0, sublocation.name.split(" ").length -1 ).join(' ')}}</span> -->
                <span :class="checkNameLength(sublocation.name)">{{ sublocation.name.toUpperCase().split(" ").pop() }}</span>
              </div>
            </div>
          </v-flex>
        </v-layout>        
      </v-container>
      
      <!-- <div class="video_intercom__body_section">
        <div class="call_buttons">
          <img
            v-if="calling"
            src="@/assets/img/hang.png"
            class="call_buttons__button"
            @click="hangUp()"
          />
        </div>

        <h5 class="call_message" v-if="on_call">
          No fue posible establecer la comunicación en este momento con <b>{{ sublocation_on_call.name.toUpperCase() }}</b>, por favor intenta marcar nuevamente.
        </h5>
      </div> -->
    </section>
  </article>
</template>
<script>
// import VideoCallModal from "@/components/modals/VideoCallModal.vue";
import moment from 'moment';
export default {
  data() {
    return {
      api: null,
      date: null,
      time: null,
      current_location: null,
      connection_id: "",
      show_video_call: false,
      calling: false,
      audio: null,
      timerInterval: "",
      on_call: false,
      location_name: '',
      sublocations: [],
      sublocation_on_call: {id: null, name: null}
    };
  },
  created() {
    setInterval(() => this.updateCurrentTime(), 1000);
    console.log("getDeviceId()", this.buildDeviceId());
    this.connection_id = this.buildDeviceId();
    this.current_location = this.$route.query.id ? this.$route.query.id : null;

    this.getSublocationsList();
  },
  mounted() {    
  },
  components: {
    // "video-call-modal": VideoCallModal,
  },
  computed: {    
  },
  channels: {
    VirtualGuardChannel: {
      connected() {
        console.log("subscribeVirtualGuardChannel");
      },
      received(data) {
        console.log('Channel received data', data)
        const connectionId = data.data.connection_id;

        // Verifica si el mensaje proviene de la misma conexión (emisor)
        if (connectionId !== this.connection_id) {
          if (data.data.answer_call == true) {
            this.answerCall();
          } else if (data.data.on_call == true) {
            this.onCall();
          } else if (data.data.calling == false) {
            console.log("----------> hag up call");
            this.calling = false;
            this.audio.pause();
            clearInterval(this.timerInterval);
          } else if (data.data.notify_call_ended == true) {
            console.log("-----------> notify call ended");
            this.finshVideoCall();
          }
        }
      },
      disconnected() {
        console.log("Disconnected to  channel!");
      },
    },
  },
  methods: {
    getSublocationsList() {
      var self = this;
      try {
        this.$http.get("digital_guard/available_sublocations", {
          params: {
            location_id: this.current_location,
          },
          headers: {
            "X-Device-ID": this.getDeviceId(),
          },
        })
        .then(function(response) {
          console.log("--------> RESPONSE SUBLOCATIONS INFO", response);
          self.location_name = response.body.data.location_name;
          self.sublocations = response.body.data.sublocations;
        });
      } catch (e) {
        alert(e.message);
      }
    },
    callSublocation(sublocation){
      this.unSubscribeVirtualGuardChannel()
      try {
        this.$http.post('digital_guard/send_call_notifications',{
          sublocation_id: sublocation.id
        }).then(function(response){
          console.log('Response:', response.body.ans)
        }, function(response){
          console.log(response)
        })
      } catch (e) {
        console.log(e)
      }
      this.sublocation_on_call = sublocation

      console.log("---------> calling");
      this.subscribeVirtualGuardChannel(sublocation.id);

      this.on_call = false;
      this.calling = true;
      this.audio = new Audio(require("@/assets/audio/calling.mp3"));
      this.showModalActive = true;
      this.audio.play();

      let timerSeconds = 0;
      this.timerInterval = setInterval(() => {
        timerSeconds++;
        console.log("-------> timerSeconds", timerSeconds);

        if (timerSeconds === 45) {
          this.on_call = true;
          this.hangUp();
          clearInterval(this.timerInterval); // Detener el intervalo después de 45 segundos
        }
      }, 1000);
    },
    answerCall() {
      clearInterval(this.timerInterval);
      this.calling = false;
      this.audio.pause();
      this.on_call = false;
      this.show_video_call = true;
      this.getToken();
    },
    hangUp() {
      console.log("--------> hang up call");
      this.calling = false;
      this.audio.pause();
      clearInterval(this.timerInterval);

      const room = `room_virtual_guard_${this.sublocation_on_call.id}`;
      this.$cable.perform({
        channel: "VirtualGuardChannel",
        action: "send_message",
        data: {
          room: room,
          message: "hangUp",
          calling: false,
          sublocation_id: this.sublocation_on_call.id,
          connection_id: this.connection_id,
        },
      });
    },
    subscribeVirtualGuardChannel(sublocation_id) {
      console.log('--------> Subscribing to sublocation id channel:', sublocation_id)
      if(sublocation_id !== null){
        this.$cable.subscribe({
          channel: "VirtualGuardChannel",
          room: `room_virtual_guard_${sublocation_id}`,
          sublocation_id: sublocation_id,
        });
      }
    },
    unSubscribeVirtualGuardChannel() {
      this.$cable.unsubscribe("VirtualGuardChannel");
    },
    updateCurrentTime() {      
      var day = moment().format("DD ")
      var month = moment().format("MMMM ").toString()
      var capitalizedMonth = month.charAt(0).toUpperCase() + month.slice(1);
      var year = moment().format("YYYY ")
      this.date = day + capitalizedMonth + year
      this.time = moment().format("h:mm a");
    },
    checkNameLength(sublocation_name){
      return sublocation_name.split(" ").pop().length > 5 ? 'word' : 'number'
    },
    getToken() {
      console.log("get token");
      try {
        this.$http.get("digital_guard/generate_jwt", {
            params: {
              sublocation_id: this.sublocation_on_call.id,
            },
            headers: {
              "X-Device-ID": this.getDeviceId(),
              Authorization: process.env.VUE_APP_JITSI_TOKEN_AUTHORIZATION,
            },
          })
          .then(function(response) {
            console.log("-------->RESPONSE TOKEN", response);
            var token = response.body.data;
            this.launchVideoCall(token);
          });
      } catch (e) {
        alert(e.message);
      }
    },
    launchVideoCall(token) {
      console.log("--------> launchVideoCall");
      const domain = process.env.VUE_APP_JITSI_URL;
      const options = {
        roomName: `room_virtual_guard_${this.sublocation_on_call.id}`,
        width: "100%",
        height: "100%",
        lang: "es",
        parentNode: document.querySelector("#meet"),
        userInfo: {
          email: "user@fusepong.com",
          displayName: "Usuario",
          role: "user",
        },
        configOverwrite: {
          prejoinPageEnabled: false,
          disableDeepLinking: true
        },
        interfaceConfigOverwrite: {
          TOOLBAR_ALWAYS_VISIBLE: true
        },
        jwt: token,
      };

      this.api = new window.JitsiMeetExternalAPI(domain, options);

      this.api.addListener("readyToClose", () => {
        console.log("---------------> readyToClose");
        this.$emit("close-video-call-modal");
        const room = `room_virtual_guard_${this.sublocation_on_call.id}`;
        this.$cable.perform({
          channel: "VirtualGuardChannel",
          action: "send_message",
          data: {
            room: room,
            message: "notifyCallEnded",
            notify_call_ended: true,
            sublocation_id: this.sublocation_on_call.id,
            connection_id: this.connection_id,
          },
        });
        // Colgar la llamada
        this.api.dispose();
        this.show_video_call = false;
        this.hangUp()
        this.sublocation_on_call = {id: null, name: null}
        setTimeout(() => this.unSubscribeVirtualGuardChannel(), 2000); // Desconectarse del canal después de dos segundos
      });
    },
    finshVideoCall(){
      this.show_video_call = false;
      this.hangUp()
      this.sublocation_on_call = {id: null, name: null}
      setTimeout(() => this.unSubscribeVirtualGuardChannel(), 2000); // Desconectarse del canal después de dos segundos
    }
  },
};
</script>

<style scoped></style>
