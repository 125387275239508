<template>
  <v-navigation-drawer v-model="drawer" fixed clipped class="navigation__container" app>
    <v-list-item class="navigation__container--header">
      <v-list-item-content>
        <v-list-item-title class="text-h4">
          <i class="material-icons">groups</i> Clientes
        </v-list-item-title>
        <!-- <v-badge v-if="monitored_company.pending_events > 0" color="red" :content="monitored_company.pending_events">{{ monitored_company.name }}</v-badge> -->
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list class="navigation__container--drawer">
      <v-list-item @click="changeRoute(i, monitored_company)" v-for="(monitored_company, i) in getMonitoredCompanies()" :key="monitored_company.id" class="navigation__container--item"
        :class="[monitored_company.pending_events > 0 ? 'alert' : 'normal', i === getSelectedCompany() && monitored_company.pending_events > 0 ? 'selected_alert': i === getSelectedCompany() && monitored_company.pending_events === 0 ? 'selected' :'']">
        <v-list-item-content>
          <!-- <v-list-item-title class="navigation__container--item_text_container" v-if="monitored_company.pending_events > 0">
            <v-badge color="red" :content="monitored_company.pending_events">{{ monitored_company.name }}</v-badge>
          </v-list-item-title> -->
          <v-list-item-title :title="monitored_company.name">
            <v-row no-gutters class="navigation__container--item_content">
              <v-col class="navigation__container--item_title">
                {{ monitored_company.name }}
              </v-col>
              <v-col cols="auto">
                <arrow-circle-right-icon width="30" height="30" v-if="i === getSelectedCompany()" :color="monitored_company.pending_events > 0 ? '#ff786f' : ''"></arrow-circle-right-icon>
                <circle-icon width="30" height="30" v-else-if="monitored_company.pending_events > 0"></circle-icon>
              </v-col>
            </v-row>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <div class="drawer__close_section" @click="closeSession()">
      Cerrar Sesión
    </div>
  </v-navigation-drawer>
</template>

<script>
import CircleIcon from "@/components/shared/icons/CircleIcon.vue";
import ArrowCircleRightIcon from "@/components/shared/icons/ArrowCircleRightIcon.vue";
  export default {
    data: () => ({
      drawer: null,
      selected_item:'',
      selected_subitem:'',
      current_name: 'Compañías Monitoreadas',
      items: [
        {
          title: 'Compañías Monitoreadas',
          divider: true,
          principal_route: '',
          section: 'companys',
          id: 0
        },
      ]
    }),
    components: {
      "circle-icon" : CircleIcon,
      "arrow-circle-right-icon" : ArrowCircleRightIcon
  },
    watch: {
      selected_item: function() {
        if(this.selected_item==='classnav'){
          this.current_name = 'Clases';
        }
      }
    },
    mounted(){
      this.subscribeToMonitorChannel()
    },
    methods:{
      changeRoute(index, company){
        if (this.$route.params.id !== company.id){
          this.updateSelectedCompany(index)
          this.$router.push({name: 'monitoring', params: {id: company.id}})
        }
      },
      closeSession(){
        this.updateUserToken(null)
        this.updateIsLogin(false)
        this.updateUserId('')
        this.updateMonitoredCompanies([])
        this.updateSelectedCompany(null)
        this.$router.push({name:"login"})
      },
      subscribeToMonitorChannel() {
        this.unsubscribeFromMonitorChannel()
        console.log('Entra a suscribirse al canal del Monitor!!!', this.getUserId())
        // Suscribirse al canal del monitor
        this.$cable.subscribe({
          channel: "EventsMonitoringChannel",
          room: `room_events_monitoring_${this.getUserId()}`,
          monitor_id: this.getUserId(),
        });
      },
      unsubscribeFromMonitorChannel() {
        this.$cable.unsubscribe("EventsMonitoringChannel")
      },



    }
  }
</script>
