<template>
<v-app>
  <v-main class="monitoring">
    <div id="main-wrapper">
      <router-view></router-view>
    </div>
  </v-main>
</v-app>
</template>

<script>
  export default {
    data: () => ({ drawer: true }),
    mounted(){
      this.buildDeviceId()
    }
  }
</script>

<style lang="scss">
  @import './assets/stylesheets/application.scss';
  header{
    left: 0 !important;
  }
</style>
