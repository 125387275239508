import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'

Vue.mixin({
  data(){
    return {
      company:{
        user_name: '',
        description:"",
        document_number: "",
        email: "",
        company_name: "",
        address: "",
        identify_number: "",
        dashboard:{
          users: false,
          offers: false,
          rewards: false,
          access: false
        },
        offers:{
          redemption_form:""
        },
        rewards:{
          user_characteristics: false,
          segments: false,
          rewards: false
        },
        access:{
          general_access:'',
          without_internet_access: false,
          keyboard_access: false,
          dynamic_invitations: false,
          room_bookings: '',
          courtesies: false,
          covenants: false,
          reports: false,
          alerts: '',
          counter: false,
          user_access: false
        },
        locations:{
          offers_and_rewards: false,
          sublocations: {
            time_out: false,
            bookings: false
          },
          holidays: false
        },
        personal_trainer:{
          personal_trainer: false
        },
        messages:{
          notifications:false,
          segment_mails: false
        },
        news:{
          general_news: false,
          video_news: false,
          twits_news:false
        },
        events:{
          tickets: false
        },
        company_connections:{
          company_connections: false
        },
        client_information: {
          us: false,
          contact_us: false
        },
        personalize:{
          web_page: false,
          mobile_app: false,
          colors: {
            principal:'',
            secundary:''
          },
          app_logo: '',
          principal_sections: [],
          secondary_sections: [],
          personalize_mails: false
        },
        inside_request:{
          payment_certificate: false,
          work_certificate: false,
          contribution_certificate: false
        },
        api_connections:{
          crm: false,
          end_points: false
        }
      }
    }
  },
  methods:{
    checkToken(token){
      if (this.getUserToken() != token) {
        this.updateUserToken(token);
      }
    },
    encrypt(data) {
      var CryptoJS = require("crypto-js");
      var payload = "G7qeaR2Yb4DAgk92ZQHdjQ==|gfYvWJ0vVdX142/xpsGPW8Fsgc/dGeQPFtaazcUMjQo=|VF/bl6Ae5CVTtQTPCrmAgQ==".split("|");
      var iv = payload[0], hmac = payload[1], cipherText = payload[2];
      var passphraseDgst = CryptoJS.SHA256("ImAwesomeAndHappy").toString();
      var key = passphraseDgst;
      console.log("<---------CryptoJS");
      console.log(iv, hmac, cipherText, key);
      var encrypted = CryptoJS.AES.encrypt(
        JSON.stringify(data),
        CryptoJS.enc.Hex.parse(passphraseDgst),
        {iv:CryptoJS.enc.Base64.parse(iv)}
      );
      return encrypted
    },
    buildDeviceId(){
      var uuid
      if(localStorage.device_id != undefined &&
         localStorage.device_id != null &&
         localStorage.device_id != ''){
        uuid = localStorage.device_id
      } else {
        uuid = this.$uuid.v1()
        localStorage.device_id = uuid
      }
      this.updateDeviceId(uuid);
      return uuid
    },
    clearData(){
      var company = {
        user_name: '',
        description:"",
        document_number: "",
        email: "",
        company_name: "",
        address: "",
        identify_number: "",
        dashboard:{
          users: false,
          offers: false,
          rewards: false,
          access: false
        },
        offers:{
          redemption_form:"",
          favorites: false
        },
        rewards:{
          user_characteristics: false,
          segments: false,
          rewards: false
        },
        access:{
          general_access:'',
          without_internet_access: false,
          keyboard_access: false,
          dynamic_invitations: false,
          room_bookings: '',
          courtesies: false,
          covenants: false,
          reports: false,
          alerts: false,
          counter: false,
          user_access: false
        },
        locations:{
          offers_and_rewards: false,
          sublocations: {
            time_out: false,
            bookings: false
          },
          holidays: false
        },
        personal_trainer:{
          personal_trainer: false
        },
        messages:{
          notifications:false,
          segment_mails: false
        },
        news:{
          general_news: false,
          video_news: false,
          twits_news:false
        },
        events:{
          tickets: false
        },
        company_connections:{
          company_connections: false
        },
        client_information: {
          us: false,
          contact_us: false
        },
        personalize:{
          web_page: false,
          mobile_app: false,
          colors: {
            principal: '',
            secundary:''
          },
          app_logo: '',
          principal_sections: [],
          secondary_sections: [],
          personalize_mails: false
        },
        inside_request:{
          payment_certificate: false,
          work_certificate: false,
          contribution_certificate: false,
          payroll_credit: false
        },
        api_connections:{
          crm: false,
          end_points: false
        }
      }
      this.updateCompany(company)
      this.updateValidationOffers(false)
      this.updateValidationOffers(false)
      this.updateValidationRewards(false)
      this.updateValidationFavorites(false)
      this.updateValidationInvitations(false)
      this.updateValidationRoomBookings(false)
      this.updateValidationLocations(false)
      this.updateValidationPersonalTrainer(false)
      this.updateValidationNews(false)
      this.updateValidationEvents(false)
      this.updateValidationCompanyConnections(false)
      this.updateValidationUs(false)
      this.updateValidationContactUs(false)
      this.updateValidationInsideRequest(false)
    },
    ...mapActions([
      'updateUserToken',
      'updateDeviceId',
      'updateIsLogin',
      'updateIsInAlert',
      'updateUserIdEncrypt',
      'updateUserType',
      'updateUserId',
      'updateShowDrawer',
      'updateMonitoredCompanies',
      'updateSelectedCompany',
      'updateLoader',
      'updateEmail'
    ]),
    ...mapGetters([
      'getUserToken',
      'getDeviceId',
      'getIsLogin',
      'getIsInAlert',
      'getUserIdEncrypt',
      'getUserType',
      'getUserId',
      'getShowDrawer',
      'getMonitoredCompanies',
      'getSelectedCompany',
      'getLoader',
      'getEmail'
    ])
  }
})
